<template>
    <div>
        <div class="grey lighten-3 pa-2 mb-2" style="border-radius: 8px;" v-if="parentSearch">
            <v-row no-gutters class="mx-3">
                <v-col cols="12">
                    Parent <span class="red--text">{{ parentError }}</span>
                </v-col>
                <v-col cols="12">
                    <!-- <finder append-icon="mdi-magnify" @find="addFoundCustomer" search-category="customers" label="Find customer by phone, email or name" 
                        class="mt-1" :show-new-customer-button="false"></finder> -->
                </v-col>
                <v-col cols="12" v-if="parent != null">
                    <v-list two-line>
                        <v-list-item active>
                            <v-list-item-content>
                                <v-list-item-title>{{ parent.fullName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formatPhoneNum(parent.phone) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-subtitle>{{ parent.email }}</v-list-item-subtitle>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </div>

        <v-row class="fill-height" dark>
            <v-col xs="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                <v-text-field v-model="local.firstName" label="First Name" :error-messages="firstNameError" required @input="update('firstName', local.firstName)"></v-text-field>
            </v-col>
            <v-col xs="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">
                <v-text-field v-model="local.lastName" label="Last Name" :error-messages="lastNameError" required @input="update('lastName', local.lastName)"></v-text-field>
            </v-col>
        </v-row>

        <v-row class="fill-height mt-3">
            <v-col cols="6" class="pr-2">
                <v-text-field v-model="local.birthdate" label="Birthdate" append-icon="mdi-cake-variant" 
                    mask="date" :error-messages="birthdateError"
                    hint="mm/dd/yyyy" persistent-hint @input="update('birthdate', local.birthdate)"></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2">
                <v-select v-model="local.gender" :items="genders" item-value="id" item-text="name" label="Gender" :error-messages="genderError" @change="update('gender', local.gender)"></v-select>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash';
import { _st } from '@/softech';

export default {
    props: {
        value           : { type: Object, default() { return {} } },
        valStrength     : { type: String, default: 'strong' },
        parentSearch    : { type: Boolean, default: true }
    },
    data: function() {
        return {
            genders: [
                { id: 'm', name: 'Male' },
                { id: 'f', name: 'Female' },
                { id: 'n', name: 'Neutral' }
            ],
            birthdateError: '',
            customerTypeError: '',
            firstNameError: '',
            lastNameError: '',
            genderError: '',
            parent: null,
            parentError: '',
        }
    },
    watch: {
    },
    computed: {
        local() {
            return !_st.isNU( this.value ) ? this.value : { 
                birthdate: '',
                customerTypeId: 1,
                firstName: '',
                lastName: '',
                gender: '',
                parentId: null
            };
        },
        customer() {
            return {
                customerTypeId: 1,
                firstName: this.local.firstName,
                lastName: this.local.lastName,
                fullName: this.local.firstName.trim() + ' ' + this.local.lastName.trim(),
                birthdate: this.formatDate(this.local.birthdate),
                gender: this.local.gender,
                customerId: this.local.internalCustomerId,
                parentId: this.parent == null ? null : this.parent.id
            }
        }
    },
    methods: {
        isValid() {
            let errorFound = false;
            this.customerTypeError = '';
            this.firstNameError = this.lastNameError = 
            this.birthdateError = this.genderError = '';

            if( this.local.customerTypeId < 0 || this.local.customerTypeId > 2 ) {
                this.customerTypeError = 'required';
                errorFound = true;
            }

            if( this.local.firstName.length < 3 ) {
                this.firstNameError = 'required';
                errorFound = true;
            }

            if( this.local.lastName.length < 3 ) {
                this.lastNameError = 'required';
                errorFound = true;
            }

            if( this.local.birthdate.length > 0 && !/^(((0)[0-9])|((1)[0-2]))([0-2][0-9]|(3)[0-1])\d{4}$/i.test(this.local.birthdate) && this.valStrength.toLowerCase() == 'strong' ) {
            // if( this.local.birthdate.length > 0 && !/^(((0)[0-9])|((1)[0-2]))\/([0-2][0-9]|(3)[0-1])\/\d{4}$/i.test(this.local.birthdate) && this.valStrength.toLowerCase() == 'strong' ) {
                this.birthdateError = 'invalid format (must be mm/dd/yyyy)';
                errorFound = true;
            }
            else if( this.local.birthdate.length > 0 && this.local.birthdate.substring(4, 8) > (new Date()).getFullYear() && this.valStrength.toLowerCase() == 'strong' ) {
                this.birthdateError = 'birth year cannot be in the future';
                errorFound = true;
            }

            // falta validación del parent
            if( this.parent == null && this.parentSearch ) {
                this.parentError = 'A parent customer must be selected'
                errorFound = true;
            }

            return !errorFound;
        },
        clearForm() {
            this.customerTypeError = '';
            this.firstNameError = this.lastNameError = 
            this.birthdateError = this.genderError = '';
            this.parent = null;
            this.$emit('input', { 
                birthdate: '',
                customerTypeId: 1,
                firstName: '',
                lastName: '',
                gender: '',
                parentId: null
            });
        },
        capitalizeText(t) {
            if( t.length == 0 )
                return t;

            t = t.substring( 0, 1 ).toUpperCase() + t.substring( 1 );
            t = t.split(' ');
            for( var i = 0; i < t.length; i++ ) {
                if( t[i].length == 0 )
                    continue;

                t[i] = t[i].substring( 0, 1 ).toUpperCase() + t[i].substring( 1 ).toLowerCase();
            }

            return t.join(' ');
        },
        loadCustomer(c) {
            this.local.birthdate = this.parseDate(c.birthdate);
            this.local.customerTypeId = parseInt(c.customerTypeId);
            this.local.firstName = c.firstName;
            this.local.lastName = c.lastName;
            this.local.phone = c.phone == undefined ? '' : c.phone;
            this.local.email = c.email == undefined ? '' : c.email;
            this.local.gender = c.gender;
            this.local.addressLine1 = c.addressLine1 == undefined ? '' : c.addressLine1;
            this.local.addressLine2 = c.addressLine2 == undefined ? '' : c.addressLine2;
            this.local.city = c.addressCity == undefined ? '' : c.addressCity;
            this.local.state = c.addressState == undefined ? '' : c.addressState;
            this.local.zipcode = c.addressZipcode == undefined ? '' : c.addressZipcode;
            this.local.parentId = c.parentId;
            this.internalCustomerId = c.customerId;
        },
        formatDate(b) {
            return b.substring(4, 8) + '-' + b.substring(0, 2) + '-' + b.substring(2, 4);
        },
        parseDate(b) {
            if( _st.isNU( b ) )
                return '';
            if( !b.includes( '-' ) )
                return b;

            return b.split('-')[1] + '/' + b.split('-')[2] + '/' + b.split('-')[0];
        },
        update(key, value) {
            if( key == 'firstName' || key == 'lastName' )
                value = this.capitalizeText(value);

            this.$emit('input', tap(cloneDeep(this.local), v => set(v, key, value)));
        },
        addFoundCustomer(c) {
            if( !_st.isNUE( c.parentId ) ) {
                this.mxShowMessage( 'ERROR: The parent cannot be a child customer.', 'error' );
                return;
            }

            this.parent = c;
            this.local.parentId = c.id;
            this.parentError = '';
        },
    },
    // components: {
    //     Finder: () => import('@/components/Finder.vue')
    // }
}
</script>

<style>

</style>
<template>
    <div>
        <div class="changes" v-show="itemChanges.length > 0">
            <template v-for="(item, ix) in itemChanges">
                <div class="change" :key="ix" v-if="item.type != 'NEW'">
                    <div class="arrow">→</div>
                    <div class="item">
                        <div class="styleInfo">
                            <span :class="item.type == 'ITEM' || item.type == 'ITEM & SIZE' ? 'emphasis' : ''">{{ item.item.style }}</span> - 
                            <span :class="item.type == 'SIZE' || item.type == 'ITEM & SIZE' ? 'emphasis' : ''">{{ item.size }}</span>
                        </div>
                        <div class="itemInfo">
                            <span class="date">{{ item.dateCreated }}</span> - 
                            <span class="user">{{ item.employee }}</span>
                        </div>
                    </div>
                    <div class="chip">
                        <v-chip small label class="white--text" :color="getChangeColor( item.type )">{{ item.type }}</v-chip>
                    </div>
                </div>
                <div class="new" v-else :key="ix">
                    <div class="arrow">→</div>
                    <div class="item">
                        <div class="styleInfo">
                            New item added
                        </div>
                        <div class="itemInfo">
                            <span class="date">{{ item.dateCreated }}</span> - 
                            <span class="user">{{ item.employee }}</span>
                        </div>
                    </div>
                    <div class="chip">
                        <v-chip small label class="white--text" :color="getChangeColor( item.type )">{{ item.type }}</v-chip>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { _st } from '@/softech';

export default {
    props: {
        item    : { type: Object, default: () => {} },
        changes : { type: Array, default: () => [] },
        assigned: { type: Boolean, default: false },
    },
    data: () => ({
        itemChanges     : [],
    }),
    watch: {
        changes() {
            this.getChanges();
        }
    },
    mounted() {
        this.getChanges();
    },
    methods: {
        getChanges() {
            this.itemChanges    = [];

            if( _st.isNUE( this.item ) || _st.isNUE( this.changes ) )
                return [];

            let data            = [];
            let style           = this.item.style ?? this.item.foreignId;
            let catId           = this.item.categoryId;
            let catFound        = false; // indicate that the item was found by category id
            let prodFound       = false; // indicate that the item was found by product id
            
            // this.changes = this.changes.toReversed();
            this.changes.forEach(c => {
                // look for product id
                let change = c.items.find( ci => ci.id == this.item.productId);
                prodFound = !_st.isNUE( change );

                if( !prodFound ) {
                    change = c.items.find( ci => ci.categoryID == catId);
                    catFound = !_st.isNUE( change  );
                }

                if( !prodFound && !catFound ) {
                    if( !this.assigned ) {
                        data.push({ 
                            item        : this.item,
                            size        : this.item.size,
                            type        : 'NEW',
                            dateCreated : _st.formatDate( c.dateCreated, 'YYYY-MM-DD' ),
                            employee    : c.employee.fullName
                        });
                    }
                    return;
                }

                let size = '';
                switch( catId ) {
                    case 'C'    : size = `${c.measurements.coatSize}${c.measurements.coatLength} - ${c.measurements.coatSleeve}`; break;
                    case 'P'    : size = `${c.measurements.pantWaist}${c.measurements.pantType} - ${c.measurements.pantOutseam}`; break;
                    case 'S'    : size = `${c.measurements.shirtSize}`; break;
                    case 'V'    : size = `${c.measurements.vestSize}`; break;
                    case 'CB'   : size = `${c.measurements.cummerbundSize}`; break;
                    case 'N'    : size = `${c.measurements.tieSize}`; break;
                    case 'SH'   : size = `${c.measurements.shoeSize}${c.measurements.shoeType}`; break;
                    case 'H'    : size = ''; break;
                    case 'SO'   : size = ''; break;
                }

                let prevStyle = data[data.length - 1]?.item.style;
                let prevSize = data[data.length - 1]?.size;
                // if( change.style == style && size == this.item.size )
                if( change.style == prevStyle && size == prevSize )
                    return;

                if( data.length == 0 ) {
                    // return if the last changes are equal to 
                    // the current styles
                    if( change.style == style && size == this.item.size )
                        return;
                }

                
                prevStyle = prevStyle == undefined ? style : prevStyle;
                prevSize = prevSize == undefined ? this.item.size : prevSize;

                let type = '';
                if( change.style != prevStyle && size != prevSize )
                    type = 'ITEM & SIZE'
                else if( change.style != prevStyle )
                    type = 'ITEM';
                else
                    type = 'SIZE';


                data.push({ 
                    item        : change,
                    size        : size,
                    type        : type,
                    dateCreated : _st.formatDate( c.dateCreated, 'YYYY-MM-DD' ),
                    employee    : c.employee.fullName
                });
            });

            // remove duplicates
            this.itemChanges = data.filter(( item, pos, arr ) => {
                return !pos
                    || item.item.style != arr[pos - 1]?.item.style 
                    || item.size != arr[pos - 1]?.size
                    || item.type != arr[pos - 1]?.type;
            });
        },
        getChangeColor( type ) {
            if( type == 'ITEM & SIZE' )
                return 'purple';
            else if( type == 'ITEM' )
                return 'teal';
            else if( type == 'SIZE' )
                return 'blue-grey';
            else if( type == 'NEW' )
                return 'primary';
        }
    }
}
</script>

<style lang="scss" scoped>
.changes {
    margin-left: 35px;
    border-left: 2px dashed #FF5722;
    padding: 5px;
}
.changes .change {
    margin: 5px 0 10px 15px;
    color: #FF5722;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    background-color: #fff0eb;
    border-radius: 5px;
}
.changes .new {
    margin: 5px 0 10px 15px;
    color: #646464;
    display: flex;
    flex-wrap: wrap;
    background-color: #ebf5ff;
    padding: 8px;
    border-radius: 5px;
}
.scanned .changes .change,
.scanned .changes .new {
    background-color: #ecffe4;
}
.changes .change .arrow,
.changes .new .arrow {
    color: #FF5722;
    margin-right: 10px;
    font-size: 20px;
    align-self: center;
}
.changes .new .arrow {
    color: #646464;
}
.change .item,
.new .item {
    flex: 1 0;
    width: 100%;
}
.change .itemInfo,
.new .itemInfo {
    font-size: 12px;
}
.changes .change .chip,
.changes .new .chip {
    align-self: center;
}
.emphasis {
    font-size: 16px;
    font-weight: bold;
}
</style>